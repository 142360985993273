import React, { Component } from 'react';
import { conditionButton, renderButton } from '../../../helper/helperFunctions';
import { Modal, OverlayTrigger, Tooltip} from "react-bootstrap";
import { LazyLoadImage } from 'react-lazy-load-image-component';

class ImagesAi extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    renderButton = ( condition, label ) => {
        return condition && <button className="backlit-btn" style={{cursor: 'pointer'}} onClick={() => { this.props.openSimilarImageModal(this.props.image) }}>{label}</button>;
    }
    formatDate = (dateString) => {
        if (dateString && this.isValidDate(dateString)) {
            const date = new Date(dateString.replace(" ", "T")); // Convert to ISO format if necessary
            const options = { month: 'short', day: '2-digit', year: 'numeric' };
            const datePart = new Intl.DateTimeFormat('en-US', options).format(date); // Format date
            const timePart = date.toTimeString().split(" ")[0]; // Extract time as HH:mm:ss

            return `${datePart} ${timePart}`;
        }
    };
    
    isValidDate = (date) => {
        let tempDate = new Date (date);
        return tempDate instanceof Date && !isNaN(tempDate.getTime());
    }


    render() {
        const { image, section, onLoad, gridView, movedImage, isSelected } = this.props;
        return (
            <div
                style={{marginLeft: 1, marginRight: 1}}
                id={`image-row-${section === 'Producer' ? `${section}-${image.producer_sort_order}` : `${section}-${image.user_sort_order}`}`}
                className= {`image-rows${image.highlighted ? ' highlighted' : ''} ${section === 'Edited' && image.is_duplicate_group  ? ' duplicate_underline' : ''} ${section === 'Edited' && image.is_inappropriate  ? ' is_inappropriate_underline' : ''} ${section === 'Edited' && image.is_low_quality  ? ' is_low_quality_underline' : ''} ${(image.exif_date_taken == null || !this.isValidDate(image.exif_date_taken)) ? ' nonexif_underline' : ''} ${section === 'Producer' && isSelected ? ' highlight-select-ai' : ''}`}
                >
                
                {/* rotateImageAi */}
                <div className='ai_img_btn'>
                    {
                        section === 'Producer' &&
                        <div className='flexElem mb-sm' style={{justifyContent: 'center'}}>
                            <div style={{margin: '0 10px', cursor: 'pointer'}} onClick={() => {
                                this.props.rotateImageAi(image, 'left')
                                }}>
                                <OverlayTrigger placement="left" overlay={<Tooltip id="tooltip">Rotate Left</Tooltip>}>
                                    <i style={{fontSize: '14px', width: '100%'}} className="fa fa-undo"
                                    aria-hidden="true"></i>
                                </OverlayTrigger>
                            </div>
                            
                            <div style={{margin: '0 10px', cursor: 'pointer'}} onClick={() => {
                                this.props.rotateImageAi(image, 'right')
                                }}>
                                <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip">Rotate Right</Tooltip>}>
                                    <i style={{fontSize: '14px', width: '100%'}} className="fa fa-repeat"
                                    aria-hidden="true"></i>
                                </OverlayTrigger>
                            </div>



                            <div style={{margin: '0 10px', cursor: 'pointer'}} onClick={() => {
                                this.props.handleSetAiImageFlag(image, 'use_in_full_video')
                                }}>
                                <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip">Do not include in video</Tooltip>}>
                                    <i style={{fontSize: '15px', width: '100%'}} className="fa fa-ban"
                                    aria-hidden="true"></i>
                                </OverlayTrigger>
                            </div>

                            <div style={{margin: '0 10px', cursor: 'pointer'}} disabled={movedImage ? false : true} onClick={() => {this.props.moveImageHandler(image)}}>
                                <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip">Click to drop the selected image</Tooltip>}>
                                    <i style={{fontSize: '15px', width: '100%'}} className="fa fa-arrow-down"
                                    aria-hidden="true"></i>
                                </OverlayTrigger>
                            </div>

                            <div style={{margin: '0 10px', cursor: 'pointer'}} onClick={() => {
                                this.props.setMoveImageId(image.id)
                                }}>
                                <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip">Click to move this image</Tooltip>}>
                                    <i style={{fontSize: '15px', width: '100%'}} className="fa fa-arrow-up"
                                    aria-hidden="true"></i>
                                </OverlayTrigger>
                            </div>
                            
                        </div>
                    }
                    {/* rotateImageAi */}

                    {/* Include in video icon in Edited row*/}
                    {
                        section === 'Edited' && image.use_in_full_video !== 1 &&
                            <div className='mb-sm' style={{margin: '0 10px', cursor: 'pointer'}} onClick={() => {
                                this.props.handleIncludeInFullVideo(image, 'use_in_full_video')
                                }}>
                                <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip">Include in video</Tooltip>}>
                                    <i style={{fontSize: '15px', width: '100%'}} className="fa fa-check"
                                    aria-hidden="true"></i>
                                </OverlayTrigger>
                            </div>
                    }
                    <LazyLoadImage
                        section={section}
                        src={image.resized_image_path.replace('SIZE', 'thumb_large') + "?updated_at=" + image.updated_at}
                        alt='image'
                        onClick={(e) => {this.props.selectImageHandler(e, image)}}
                        className='image-style'
                        placeholder={<div><img src={"/images/loading200.gif"} alt="Loading..." /></div>}
                        visibleByDefault={image.src === '/images/' + image.placeholder}
                        effect="blur"
                        threshold='1'
                    />
                    
                </div>
                <div className='image-btn' >
                    <span className='position-ai'>Position {section === 'Producer' ? +image.producer_sort_order : +image.user_sort_order }</span>
                    {
                        section === 'Producer' && image.producer_sort_order !== image.user_sort_order &&
                            <button className='from-btn'>
                                {'From ' + image.user_sort_order}
                            </button>
                    }
                    {
                        section === 'Edited' && image.producer_sort_order !== image.user_sort_order && image.use_in_full_video === 1 &&
                            <button className='from-btn'>
                                {'To ' + image.producer_sort_order}
                            </button>
                    }
                </div>
                <div>
                    {
                        
                        conditionButton(image)
                    }
                    {
                        // && image.no_of_persons_detected < 5
                        (image.is_duplicate_group === 1 && image.use_in_full_video !== 1) ?
                            image.similarity_percentage * 100 >= 100
                            ? 
                                this.renderButton((true && (image.use_in_full_video !== 1 && (image.similarity_percentage && image.similarity_percentage * 100 == 100))), 'duplicate')
                            :
                                this.renderButton((image.is_duplicate_group === 1 && image.use_in_full_video !== 1), 'Similar')
                                // image.no_of_persons_detected < 5 
                                // ?
                                //     this.renderButton((image.is_duplicate_group === 1 && image.use_in_full_video !== 1), 'Similar')
                                // :
                                //     this.renderButton((image.is_duplicate_group === 1 && image.use_in_full_video !== 1), 'Similar Group')
                        :
                        ""
                    }
                </div>
                {
                    this.props.showImgData &&
                    <React.Fragment>
                        <div className="galleryInfo p-sm">
                            <OverlayTrigger placement="bottom" overlay={
                                <Tooltip id={image.id}>
                                    Date
                                </Tooltip>
                            }>
                                <span className='text-muted dark text_14'>{this.formatDate(image.exif_date_taken)}</span>
                            </OverlayTrigger>
                            <OverlayTrigger placement="bottom" overlay={
                                <Tooltip id={image.id}>
                                    Photo Name
                                </Tooltip>
                            }>
                                <span className='galleryId text_14'>{image.id}</span>
                            </OverlayTrigger>
                        </div>
                    </React.Fragment>
                }
            </div>
        );
    }
}


export default ImagesAi;